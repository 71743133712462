import React from "react";
import PropTypes from "prop-types";

import ChargerCard from "../ChargerCard/ChargerCard"
import LoadingSpinner from "../LoadingSpinner/LoadingSpinner";
import { FormattedMessage } from "react-intl"

const ChargerCatalog = ({
  homeChargers,
  selectedVehicle
}) => {

  if (!homeChargers) return <LoadingSpinner />;

  if (!homeChargers.length) {
    return (
      <div className="text-center">
        <p className="h4">
          <FormattedMessage
            id="evs.chargerCatalog.noChargers"
            defaultMessage="No Chargers found"
            description="No Chargers found"
          />
        </p>
      </div>
    );
  }

  const renderCards = homeChargers.map((charger, index) => {
    return (
        <div key={index} className="evc-card ChargerCard" data-charger-id={charger.charger_id}>
            <ChargerCard
              charger={charger}
              selectedVehicle={selectedVehicle}
            />
        </div>
    );
  });

  return <div id="home-chargers-catalog" className="render-cards-container">{renderCards}</div>;
};

export default ChargerCatalog;

ChargerCatalog.propTypes = {
    homeChargers: PropTypes.array,
    selectedVehicle: PropTypes.object
};
