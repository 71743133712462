import fetchWrapper from "../utils/fetch/fetchWrapper";
const fetchElectricians = async params => {
    // Remove null params
    Object.keys(params).forEach(key => params[key] == null && delete params[key]);
  
    let url = new URL(`${process.env.REACT_APP_API_HOST}/electricians`);
  
    let searchParams = new URLSearchParams(params);
  
    url.search = searchParams;
   const json = await fetchWrapper(url, {
     method: "GET",
   });
    const electricians = json.electricians;
    const michiganElectricians = electricians.filter(electrician => electrician.state === "MI" && electrician.name !== "State Electric Company, Inc.");
    return michiganElectricians;
  };

export default fetchElectricians;