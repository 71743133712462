import fetchWrapper from "../utils/fetch/fetchWrapper";
const fetchHomeChargers = async params => {
  // Remove null params
  Object.keys(params).forEach(key => params[key] == null && delete params[key]);

  let url = new URL(
    `${process.env.REACT_APP_API_HOST}/chargers`
  );

  let searchParams = new URLSearchParams(params);

  url.search = searchParams;

  const json = await fetchWrapper(url, {
    method: "GET",
  });
  const homeChargers = json.chargers ? json.chargers : json;

       const filteredChargersEnergy = homeChargers.filter(({ make, certifications }) => {
              const certificationsArray = certifications ? certifications.split(", ").map((cert) => cert.toLowerCase()) : [];
              return (certificationsArray.includes("ul") && certificationsArray.includes("energy star"));
       });

       const filteredChargersManufacturer = homeChargers.filter(({ make, certifications }) => {
              const isManufacturerCharger = make === 'Tesla' || make === 'Rivian' || make === "Ford" || make === "Chevrolet";
              return isManufacturerCharger;
       });

       const combinedFilteredChargers = [...filteredChargersEnergy, ...filteredChargersManufacturer];
       const uniqueChargers = combinedFilteredChargers.reduce((acc, charger) => {
          if (!acc.some(item => item.charger_id === charger.charger_id)) {
              acc.push(charger);
          }
          return acc;
      }, []);

       return uniqueChargers;
};

export default fetchHomeChargers;