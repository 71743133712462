import fetchWrapper from "../utils/fetch/fetchWrapper";
const getEquivalent = async (handle, zipCode) => {
    let url = new URL(
        `${process.env.REACT_APP_API_HOST}/vehicles/${handle}?postcode=${zipCode}`
      );

      const json = await fetchWrapper(url, {
        method: "GET",
      });
      const equivalentGasVehicle = json?.vehicle?.equivalent_gas_vehicle
      
      return equivalentGasVehicle

}

export default getEquivalent